<template>
  <f-card
    :header="$t('labels.newPassword')"
    header-tag="header"
  >
    <template #footer>
      <h6
        align="center"
        class="mb-0"
      >
        {{ $t('labels.setUserToActivate') }}
      </h6>
    </template>
    <f-form @submit="onSubmit">
      <f-col
        class="required"
        md="12"
      >
        <f-input
          v-model="password"
          :label="$t('labels.password')"
          name="password"
          rules="required"
          type="password"
        />
      </f-col>
      <f-col
        class="required"
        md="12"
      >
        <f-input
          v-model="confirmPassword"
          :label="$t('labels.confirmPassword')"
          name="confirmPassword"
          rules="required|confirmed:password"
          type="password"
        />
      </f-col>
      <div class="form-footer mt-5">
        <f-button
          :label="$t('labels.submit')"
          type="submit"
          variant="primary btn-block"
        />
      </div>
    </f-form>
  </f-card>
</template>

<script>
export default {
  data() {
    return {
      password: '',
      confirmPassword: '',
      token: null,
      isProduction: process.env.NODE_ENV === 'production'
    }
  },
  async mounted() {
    this.token = this.$route.query.token

    if (this.isProduction) {
      await this.$recaptchaLoaded()
    }
  },
  methods: {
    async onSubmit() {
      let recaptchaToken

      if (this.isProduction) {
        recaptchaToken = await this.$recaptcha('login')
      }

      await this.$store.dispatch('activateUser', {
        password: this.password,
        token: this.token,
        recaptchaToken
      })
      this.$flex.notification.success(
        this.$t('messages.accountActivatedSuccessfully')
      )
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
